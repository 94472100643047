"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInternalCurrentUser = exports.signOut = exports.currentUserSliceSubscription = exports.subscribeToCurrentUserSlice = exports.currentUserSubscription = exports.optimisticallyUpdateLocalUser = exports.getCurrentUser = exports.subscribeToCurrentUser = void 0;
var analytics_1 = require("@centered/analytics");
var helpers_1 = require("@centered/helpers");
var types_1 = require("@centered/types");
var react_1 = require("@xstate/react");
var equal = require("fast-deep-equal/es6");
var luxon_1 = require("luxon");
var xstate_1 = require("xstate");
var config_1 = require("../config");
var machine = 
/** @xstate-layout N4IgpgJg5mDOIC5QGECuAndYB2AXABAKqxjr4CyAhgMYAWAltmAHTK1jUDWjU+AyrQD2qADYR8AOUG56AMwCeAYkSgADoNj0Zg7CpAAPRAFoALAAYAjM3NmAnCfO2A7C6cBmADQh5iJ7YBszLZuTv4ArABMYW4W-v4AHBEAvkleaJg4BMSkFDQMTMwAkhAiYIoAgqi4tPy4lLhg+GyU2DAQeuqa2rpIBsYWTsxmLg4mtrbx0S7x-l4+CKFWcREWbhFOK8EzKWkYWHhEJGRUdIwsxaWKADL0sA09ahpa9Dp6hgjxbszu-mbxq-4LLFJiY5ogAoF-GNzBYomZ-E4TBYdiB0vsskdcqcChcygAFVAAIxE9GohxyV0ElAgkA6T26b2M6y+wSRJjCWz+E1B3l8ZjMQXhIVsEXiiRCKLRmXJxzyZyKJXx6HoADd6o1smRKdTab1Os9Xr13kY1vFvk47NEJmt7GCEByTMwofy3FCBpb4pK9tLNVj8udFYpCNgSXccHSui8Hn0EEZYoFJpE2eEwmYYp5eQgLJZBmFfqLXVFIm5bF6MgdfSd-QrLgB5VQyAC2txk1EoIhE8iIqgg6vwlLbIhlEYN0eN2cGFgmFniiKLEQSPPm2YmQScYUiaaiY1sYRSqRA2EENPgvSlFcxVflbA43Fa-CEonEUhkChHDKNxjC6+Ys7MJgiP5YX+Ex4jtfxxmYIEzA5fw3FnDkRTLdEZT9eVcXfKNGVjMVvn-AJYlCNwTDiCw7Vsf8nQnb8S3WaFkJ9S85SYTDDVAY1EN-C0AKA0ULFAu03DCJYbAA5wzAiexon3JIgA */
(0, xstate_1.createMachine)({
    predictableActionArguments: true,
    context: {
        privateUser: null,
        publicUser: null,
        firebaseUser: null,
        hasLoadedPublicUserOnce: false,
        hasLoadedPrivateUserOnce: false,
        user: null,
        listeners: {},
    },
    tsTypes: {},
    schema: { context: {}, events: {} },
    preserveActionOrder: true,
    invoke: [
        {
            src: 'authStateSubscription',
        },
        {
            src: 'publicUserSubscription',
            id: 'publicUserSubscription',
        },
        {
            src: 'privateUserSubscription',
            id: 'privateUserSubscription',
        },
    ],
    id: 'Current User Machine',
    initial: 'Idle',
    states: {
        'Checking Should Notify': {
            always: [
                {
                    actions: ['mergeUserProfiles', 'notifyListeners'],
                    cond: 'should notify',
                    target: 'Idle',
                },
                {
                    target: 'Idle',
                },
            ],
        },
        Idle: {
            on: {
                'Auth State Changed': {
                    actions: [
                        'setFirebaseUser',
                        'forwardToPublicUserSubscription',
                        'forwardToPrivateUserSubscription',
                        'setLoggedInCookie',
                    ],
                },
                Listen: {
                    actions: ['listen', 'notifyFirstListen'],
                },
                'Public User Loaded': {
                    actions: 'setPublicUser',
                    target: 'Checking Should Notify',
                },
                'Private User Loaded': {
                    actions: 'setPrivateUser',
                    target: 'Checking Should Notify',
                },
                Unlisten: {
                    actions: 'unlisten',
                },
                'Optimistically Update Local User': {
                    actions: ['setUser', 'notifyListeners'],
                },
            },
        },
    },
}, {
    guards: {
        'should notify': function (context) {
            // We have both profiles loaded
            return Boolean(context.firebaseUser && context.privateUser && context.publicUser) ||
                // Or both are null
                (!context.firebaseUser &&
                    !context.privateUser &&
                    !context.publicUser);
        },
    },
    actions: {
        forwardToPublicUserSubscription: (0, xstate_1.forwardTo)('publicUserSubscription'),
        forwardToPrivateUserSubscription: (0, xstate_1.forwardTo)('privateUserSubscription'),
        listen: (0, xstate_1.assign)({
            listeners: function (context, event) {
                var _a;
                return (__assign(__assign({}, context.listeners), (_a = {}, _a[event.listenerId] = event.listener, _a)));
            },
        }),
        mergeUserProfiles: (0, xstate_1.assign)({
            user: function (context) {
                var _a, _b, _c, _d, _e;
                var user = context.firebaseUser && context.publicUser && context.privateUser
                    ? __assign(__assign(__assign({}, context.publicUser), context.privateUser), { firebaseAuthUser: context.firebaseUser, isCenteredEmployee: Boolean((_a = context.firebaseUser.email) === null || _a === void 0 ? void 0 : _a.endsWith('@centered.app')) }) : null;
                // If the user's premium status changes we need to force refresh the users custom claims
                // So that we can reset the stripeRole claim on their user object and security rules work as expected
                var previousIsPremium = (_c = (_b = context.user) === null || _b === void 0 ? void 0 : _b.isPremium) !== null && _c !== void 0 ? _c : false;
                var isPremium = (_d = user === null || user === void 0 ? void 0 : user.isPremium) !== null && _d !== void 0 ? _d : false;
                if (user && previousIsPremium !== isPremium) {
                    (_e = config_1.auth.currentUser) === null || _e === void 0 ? void 0 : _e.getIdTokenResult(true).catch(function (e) { return console.error('Could not update user claims', e); });
                }
                return user;
            },
        }),
        notifyFirstListen: function (context, event) {
            event.listener(context.user);
        },
        notifyListeners: function (context) {
            return Object.values(context.listeners).forEach(function (listener) {
                return listener(context.user);
            });
        },
        setFirebaseUser: (0, xstate_1.assign)({
            firebaseUser: function (_context, event) { return event.firebaseUser; },
        }),
        setLoggedInCookie: function (_context, event) {
            var currentUser = event.firebaseUser;
            if (!global.document)
                return;
            // set cookie if user is logged in
            if (currentUser) {
                // set cookie
                document.cookie = "user=".concat(currentUser.uid);
            }
            else {
                // remove cookie
                document.cookie = "user=;";
            }
        },
        setPrivateUser: (0, xstate_1.assign)(function (_context, event) { return ({
            hasLoadedPrivateUserOnce: true,
            privateUser: event.user,
        }); }),
        setPublicUser: (0, xstate_1.assign)(function (_context, event) { return ({
            hasLoadedPublicUserOnce: true,
            publicUser: event.user,
        }); }),
        setUser: (0, xstate_1.assign)({
            user: function (_context, event) { return event.user; },
        }),
        unlisten: (0, xstate_1.assign)({
            listeners: function (context, event) {
                var _a = context.listeners, _b = event.listenerId, _ignoreListener = _a[_b], listeners = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                return listeners;
            },
        }),
    },
    services: {
        authStateSubscription: function () { return function (send) {
            return config_1.auth.onAuthStateChanged(function (firebaseUser) {
                return send({ type: 'Auth State Changed', firebaseUser: firebaseUser });
            }, function (error) {
                console.error('[onAuthStateChanged] error', error);
                send({ type: 'Auth State Changed', firebaseUser: null });
                if (config_1.auth.currentUser) {
                    config_1.auth.signOut();
                }
            });
        }; },
        publicUserSubscription: function () { return function (send, onReceive) {
            var unsubscribe = function () { };
            onReceive(function (event) {
                if (event.type !== 'Auth State Changed')
                    return;
                unsubscribe();
                var firebaseUser = event.firebaseUser;
                if (!firebaseUser) {
                    send({ type: 'Public User Loaded', user: null });
                    return;
                }
                var lastUser = null;
                unsubscribe = config_1.db
                    .collection('users')
                    .doc(firebaseUser.uid)
                    .withConverter((0, config_1.converter)())
                    .onSnapshot(function (doc) {
                    var data = doc.data();
                    if (!data) {
                        return;
                    }
                    // No avatar - use default
                    var avatarUrl = data === null || data === void 0 ? void 0 : data.avatarUrl;
                    // Received google's default avatar - use ours
                    avatarUrl = (avatarUrl === null || avatarUrl === void 0 ? void 0 : avatarUrl.endsWith('/photo.jpg'))
                        ? undefined
                        : avatarUrl;
                    var user = __assign(__assign({}, data), { avatarUrl: avatarUrl, firebaseAuthUser: firebaseUser });
                    // Set default settings where the database value is undefined
                    if (!user.settings) {
                        user.settings = {};
                    }
                    Object.entries(types_1.defaultUserSettings).forEach(function (_a) {
                        var key = _a[0], value = _a[1];
                        // @ts-expect-error TS can't be sure of the value types here, but we know
                        // both objects are of type UserSettings
                        if (user.settings[key] === undefined) {
                            // @ts-expect-error
                            user.settings[key] = value;
                        }
                    });
                    // Set default premium settings where the database value is undefined
                    if (!user.premiumSettings) {
                        user.premiumSettings = {};
                    }
                    Object.entries(helpers_1.defaultUserPremiumSettings).forEach(function (_a) {
                        var key = _a[0], value = _a[1];
                        // @ts-expect-error TS can't be sure of the value types here, but we know
                        // both objects are of type UserPremiumSettings
                        if (user.premiumSettings[key] === undefined) {
                            // @ts-expect-error
                            user.premiumSettings[key] = value;
                        }
                    });
                    if (!equal(lastUser, user)) {
                        send({ type: 'Public User Loaded', user: user });
                        lastUser = user;
                    }
                    // fix account
                    if (!user.id) {
                        config_1.db.doc("users/".concat(firebaseUser.uid))
                            .update({ id: firebaseUser.uid })
                            .catch(function (e) {
                            console.error('Could not set user id', e);
                        });
                    }
                    // update the timezone if changed
                    if (user.timezone !== luxon_1.DateTime.now().zoneName) {
                        config_1.db.doc("users/".concat(firebaseUser.uid))
                            .update({ timezone: luxon_1.DateTime.now().zoneName })
                            .catch(function (e) {
                            console.error('Could not set user timezone', e);
                        });
                    }
                }, function (error) {
                    console.error('Error in users collection', error);
                });
            });
            return unsubscribe;
        }; },
        privateUserSubscription: function () { return function (send, onReceive) {
            var unsubscribe = function () { };
            onReceive(function (event) {
                if (event.type !== 'Auth State Changed')
                    return;
                unsubscribe();
                var firebaseUser = event.firebaseUser;
                if (!firebaseUser) {
                    send({ type: 'Private User Loaded', user: null });
                    return;
                }
                unsubscribe = config_1.db
                    .collection('private-users')
                    .doc(firebaseUser.uid)
                    .withConverter((0, config_1.converter)())
                    .onSnapshot(function (doc) {
                    var user = doc.data();
                    if (!user) {
                        return;
                    }
                    send({ type: 'Private User Loaded', user: user });
                    if (!user.email) {
                        config_1.db.doc("private-users/".concat(firebaseUser.uid))
                            .update({ email: firebaseUser.email })
                            .catch(function (e) {
                            console.error('Could not set user email', e);
                        });
                    }
                }, function (error) {
                    console.error('Error in private users snapshot listener', error);
                });
            });
            return unsubscribe;
        }; },
    },
});
// Boot up machine
var service = (0, xstate_1.interpret)(machine);
// Uncomment to debug this machine:
// .onTransition((state) =>
//   console.info('CurrentUser:', state.value, state.context.user),
// )
service.start();
// Public API
var nextListenerId = 0;
/**
 * A service that subscribes to the current user via the currentUser state machine.
 */
var subscribeToCurrentUser = function (listener) {
    var listenerId = nextListenerId++;
    service.send({ type: 'Listen', listenerId: listenerId, listener: listener });
    return function () { return service.send({ type: 'Unlisten', listenerId: listenerId }); };
};
exports.subscribeToCurrentUser = subscribeToCurrentUser;
/**
 * Returns the current user.
 */
var getCurrentUser = function () {
    return service.getSnapshot().context.user;
};
exports.getCurrentUser = getCurrentUser;
/**
 * Optimistically updates local user.
 */
var optimisticallyUpdateLocalUser = function (user) {
    service.send({ type: 'Optimistically Update Local User', user: user });
};
exports.optimisticallyUpdateLocalUser = optimisticallyUpdateLocalUser;
/**
 * Creates an XState service that will send the current user to your machine.
 * You just provide the event creator to make the event to your machine.
 *
 * e.g.
 * services: {
 *   userSubscription: currentUserSubscription(model.event.UPDATE_CURRENT_USER)
 * }
 */
var currentUserSubscription = function (eventCreator) {
    return function () {
        return function (send) {
            // returns unsubscribe
            return (0, exports.subscribeToCurrentUser)(function (user) { return send(eventCreator(user)); });
        };
    };
};
exports.currentUserSubscription = currentUserSubscription;
/**
 * Subscribes to a slice of the current user via the currentUser state machine.
 */
function subscribeToCurrentUserSlice(selector, callback, isEqual) {
    if (isEqual === void 0) { isEqual = function (a, b) { return a === b; }; }
    var firstResult = true;
    var previousValue = undefined;
    return (0, exports.subscribeToCurrentUser)(function (user) {
        if (!user) {
            firstResult = true;
            callback(null);
            return;
        }
        var value = selector(user);
        if (firstResult || !isEqual(value, previousValue)) {
            previousValue = value;
            firstResult = false;
            callback(value);
        }
    });
}
exports.subscribeToCurrentUserSlice = subscribeToCurrentUserSlice;
/**
 * Creates an XState service that will send a slice of the current user to your machine.
 * You just provide the event creator to make the event to your machine.
 *
 * e.g.
 * services: {
 *   userIdSubscription: currentUserSliceSubscription(
 *     model.event.UPDATE_CURRENT_USER_ID,
 *     (user) => user.id,
 *   ),
 * },
 */
var currentUserSliceSubscription = function (eventCreator, selector, isEqual) {
    if (isEqual === void 0) { isEqual = function (a, b) { return a === b; }; }
    return function () {
        return function (send) {
            // returns unsubscribe
            return subscribeToCurrentUserSlice(selector, function (slice) { return send(eventCreator(slice)); }, isEqual);
        };
    };
};
exports.currentUserSliceSubscription = currentUserSliceSubscription;
/**
 * Signs out. Wraps firebase sign out.
 */
var signOut = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!config_1.auth.currentUser) return [3 /*break*/, 2];
                (0, analytics_1.trackEvent)('Logout', {});
                return [4 /*yield*/, config_1.db.doc("users/".concat(config_1.auth.currentUser.uid)).update({ state: 'offline' })];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [4 /*yield*/, config_1.auth.signOut()];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.signOut = signOut;
/** Hook to listen to the current user */
var useInternalCurrentUser = function () {
    var _a;
    var context = (0, react_1.useActor)(service)[0].context;
    return {
        loading: !context.hasLoadedPrivateUserOnce || !context.hasLoadedPublicUserOnce,
        currentUser: (_a = context.user) !== null && _a !== void 0 ? _a : undefined,
        signOut: exports.signOut,
    };
};
exports.useInternalCurrentUser = useInternalCurrentUser;
