import { firebase, FirebaseProvider, useCurrentUser } from '@centered/firebase'
import '@draft-js-plugins/emoji/lib/plugin.css'
import '@draft-js-plugins/linkify/lib/plugin.css'
import '@draft-js-plugins/mention/lib/plugin.css'
import 'draft-js/dist/Draft.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import 'prismjs/themes/prism.css'
import { useEffect } from 'react'
import 'tailwindcss/tailwind.css'
import AuthPage from '../components/AuthPage'
import PageWrapper from '../components/PageWrapper'
import '../styles/globals.css'
import '../styles/tailwind.css'

function DashboardApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

function Page(props: AppProps) {
  const { currentUser, loading, signOut } = useCurrentUser()
  // sign out when not a centered user
  useEffect(() => {
    if (
      loading === false &&
      currentUser?.email &&
      !currentUser?.isCenteredEmployee
    ) {
      signOut()
    }
  }, [currentUser?.email])

  if (loading !== false || (currentUser && !currentUser?.isCenteredEmployee)) {
    return <button onClick={() => firebase.auth().signOut()}>Sign Out</button>
  }

  if (currentUser && props.router.asPath.startsWith('/dashboard')) {
    return <DashboardApp {...props} />
  }

  return currentUser ? <PageWrapper {...props} /> : <AuthPage />
}

function AppWrapper(props: AppProps): JSX.Element {
  return (
    <FirebaseProvider>
      <Page {...props} />
    </FirebaseProvider>
  )
}

export default AppWrapper
