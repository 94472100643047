"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeBuddySessionMatch = exports.rescheduleBuddySession = exports.renameBuddySession = exports.deleteBuddySession = exports.createBuddySessionFromCalendar = exports.createBuddySession = exports.acceptBuddySession = void 0;
var luxon_1 = require("luxon");
var uuid = require("uuid");
var config_1 = require("../config");
function acceptBuddySession(user, otherSession) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var userId, session, name, eventId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    userId = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
                    session = __assign(__assign({}, otherSession), { id: uuid.v4(), user: {
                            id: user.id,
                            name: user.name,
                            avatarUrl: user.avatarUrl,
                            isPremium: user.isPremium,
                        }, matchedUser: otherSession.user });
                    name = "Cowork w/".concat(otherSession.user.name);
                    eventId = "buddy-".concat(session.id);
                    return [4 /*yield*/, Promise.all([
                            config_1.db.doc("/buddy-sessions/".concat(session.id)).set(session, { merge: true }),
                            config_1.db
                                .doc("/private-users/".concat(userId, "/buddy-sessions/").concat(session.id))
                                .set(__assign(__assign({}, session), { name: name }), { merge: true }),
                            // Don't wait to create the calendar event since so it appears right away
                            // The backend hooks will ensure the details are correct afterwards
                            session.isAdHoc
                                ? Promise.resolve()
                                : config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).set({
                                    id: eventId,
                                    type: 'buddy',
                                    owner: session.user,
                                    buddySessionId: session.id,
                                    name: name,
                                    start: session.dateTimeInMilis,
                                    end: session.dateTimeInMilis + session.durationInMinutes * 60 * 1000,
                                    buddySession: session,
                                }),
                        ])];
                case 1:
                    _b.sent();
                    return [2 /*return*/, __assign(__assign({}, session), { name: name })];
            }
        });
    });
}
exports.acceptBuddySession = acceptBuddySession;
function createBuddySession(user, startAt, durationInMinutes, name, isAdHoc) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var userId, session, eventId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    userId = (_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
                    session = {
                        id: uuid.v4(),
                        matchId: uuid.v4(),
                        dateTimeInMilis: startAt,
                        user: {
                            id: user.id,
                            name: user.name,
                            avatarUrl: user.avatarUrl,
                            isPremium: user.isPremium,
                        },
                        durationInMinutes: durationInMinutes,
                        isAdHoc: isAdHoc,
                    };
                    eventId = "buddy-".concat(session.id);
                    return [4 /*yield*/, Promise.all([
                            config_1.db.doc("/buddy-sessions/".concat(session.id)).set(session, { merge: true }),
                            config_1.db
                                .doc("/private-users/".concat(userId, "/buddy-sessions/").concat(session.id))
                                .set(__assign(__assign({}, session), { name: name }), { merge: true }),
                            session.isAdHoc
                                ? Promise.resolve()
                                : config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).set({
                                    id: eventId,
                                    type: 'buddy',
                                    owner: session.user,
                                    buddySessionId: session.id,
                                    name: name,
                                    start: session.dateTimeInMilis,
                                    end: session.dateTimeInMilis + session.durationInMinutes * 60 * 1000,
                                    buddySession: session,
                                }, { merge: true }),
                        ])];
                case 1:
                    _b.sent();
                    return [2 /*return*/, session];
            }
        });
    });
}
exports.createBuddySession = createBuddySession;
function createBuddySessionFromCalendar(user, startAt, durationInMinutes, name) {
    if (name === void 0) { name = 'Centered Buddy Session'; }
    return __awaiter(this, void 0, void 0, function () {
        var session, eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createBuddySession(user, startAt, durationInMinutes, name)];
                case 1:
                    session = _a.sent();
                    eventId = "buddy-".concat(session.id);
                    return [4 /*yield*/, config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).set({
                            id: eventId,
                            type: 'buddy',
                            owner: session.user,
                            buddySessionId: session.id,
                            name: name,
                            start: session.dateTimeInMilis,
                            end: session.dateTimeInMilis + session.durationInMinutes * 60 * 1000,
                            buddySession: session,
                        }, { merge: true })];
                case 2:
                    _a.sent();
                    return [2 /*return*/, session];
            }
        });
    });
}
exports.createBuddySessionFromCalendar = createBuddySessionFromCalendar;
function deleteBuddySession(sessionId, askForConfirmation) {
    if (askForConfirmation === void 0) { askForConfirmation = true; }
    return __awaiter(this, void 0, void 0, function () {
        var sessionDoc, session, secondsDiff, minutesLeft, confirmed, userId, eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, config_1.db
                        .doc("/buddy-sessions/".concat(sessionId))
                        .withConverter((0, config_1.converter)())
                        .get()];
                case 1:
                    sessionDoc = _a.sent();
                    session = sessionDoc.data();
                    if (!session)
                        return [2 /*return*/];
                    secondsDiff = luxon_1.DateTime.fromMillis(session.dateTimeInMilis).diffNow('seconds').seconds;
                    minutesLeft = Math.floor((session.durationInMinutes * 60 + secondsDiff) / 60);
                    confirmed = askForConfirmation
                        ? minutesLeft < 0 ||
                            !session.matchedUser ||
                            (session.matchedUser &&
                                window.confirm('You are already matched. Are you sure you want to delete this Session?'))
                        : true;
                    if (!confirmed)
                        return [2 /*return*/];
                    userId = session.user.id;
                    eventId = "buddy-".concat(session.id);
                    return [4 /*yield*/, Promise.all([
                            config_1.db.doc("/private-users/".concat(userId, "/buddy-sessions/").concat(session.id)).delete(),
                            config_1.db.doc("/buddy-sessions/".concat(session.id)).delete(),
                            session.isAdHoc
                                ? Promise.resolve()
                                : config_1.db.doc("users/".concat(userId, "/visible-calendar-events/").concat(eventId)).delete(),
                        ])];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.deleteBuddySession = deleteBuddySession;
function renameBuddySession(sessionId, user, name) {
    return __awaiter(this, void 0, void 0, function () {
        var eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    eventId = "buddy-".concat(sessionId);
                    return [4 /*yield*/, Promise.all([
                            config_1.db.doc("/private-users/".concat(user.id, "/buddy-sessions/").concat(sessionId)).update({
                                name: name,
                            }),
                            config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).update({
                                name: name,
                                'buddySession.name': name,
                            }),
                        ])];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.renameBuddySession = renameBuddySession;
function rescheduleBuddySession(session, startAt, endAt, newMatchId) {
    return __awaiter(this, void 0, void 0, function () {
        var removeMatch, newSession, eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    removeMatch = newMatchId !== session.matchId;
                    newSession = __assign(__assign({}, session), { matchId: newMatchId, matchedUser: removeMatch ? undefined : session.matchedUser, dateTimeInMilis: startAt.getTime(), durationInMinutes: (endAt.getTime() - startAt.getTime()) / 1000 / 60 });
                    eventId = "buddy-".concat(newSession.id);
                    return [4 /*yield*/, Promise.all([
                            config_1.db
                                .doc("/private-users/".concat(newSession.user.id, "/buddy-sessions/").concat(newSession.id))
                                .withConverter((0, config_1.converter)())
                                .update(__assign(__assign({}, newSession), { name: session.name.startsWith('Cowork')
                                    ? 'Centered Buddy Session'
                                    : session.name, matchedUser: removeMatch
                                    ? config_1.firebase.firestore.FieldValue.delete()
                                    : session.matchedUser })),
                            config_1.db
                                .doc("/buddy-sessions/".concat(newSession.id))
                                .withConverter((0, config_1.converter)())
                                .update(__assign(__assign({}, newSession), { matchedUser: removeMatch
                                    ? config_1.firebase.firestore.FieldValue.delete()
                                    : session.matchedUser, name: undefined })),
                            session.isAdHoc
                                ? Promise.resolve()
                                : config_1.db
                                    .doc("users/".concat(newSession.user.id, "/visible-calendar-events/").concat(eventId))
                                    .update({
                                    start: startAt.getTime(),
                                    end: endAt.getTime(),
                                    buddySession: newSession,
                                }),
                        ])];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.rescheduleBuddySession = rescheduleBuddySession;
function removeBuddySessionMatch(sessionId, name, user) {
    return __awaiter(this, void 0, void 0, function () {
        var matchId, eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    matchId = uuid.v4();
                    eventId = "buddy-".concat(sessionId);
                    return [4 /*yield*/, Promise.all([
                            config_1.db
                                .doc("/buddy-sessions/".concat(sessionId))
                                .withConverter((0, config_1.converter)())
                                .update({
                                matchId: matchId,
                                matchedUser: config_1.firebase.firestore.FieldValue.delete(),
                            }),
                            config_1.db
                                .doc("/private-users/".concat(user.id, "/buddy-sessions/").concat(sessionId))
                                .withConverter((0, config_1.converter)())
                                .update({
                                matchId: matchId,
                                matchedUser: config_1.firebase.firestore.FieldValue.delete(),
                                name: name.startsWith('Cowork') ? 'Centered Buddy Session' : name,
                            }),
                            config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).update({
                                'buddySession.matchId': matchId,
                                'buddySession.matchedUser': config_1.firebase.firestore.FieldValue.delete(),
                            }),
                        ])];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.removeBuddySessionMatch = removeBuddySessionMatch;
