"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteCenteredSession = exports.rescheduleCenteredSession = exports.renameCenteredSession = exports.createCenteredSessionFromCalendar = void 0;
var config_1 = require("../config");
var getUser_1 = require("./getUser");
function createCenteredSessionFromCalendar(user, startAt, endAt, group) {
    return __awaiter(this, void 0, void 0, function () {
        var newDoc, session, eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!config_1.auth.currentUser) {
                        throw new Error('User should be authenticated to delete a Centered Session');
                    }
                    newDoc = config_1.db
                        .collection('/scheduled-sessions')
                        .withConverter((0, config_1.converter)())
                        .doc();
                    session = {
                        id: newDoc.id,
                        owner: {
                            id: user.id,
                            name: user.name,
                            avatarUrl: user.avatarUrl,
                        },
                        group: {
                            id: group.id,
                            name: group.name,
                            iconUrl: group.iconUrl,
                        },
                        title: 'Centered Session',
                        startAt: config_1.firebase.firestore.Timestamp.fromDate(new Date(startAt)),
                        endAt: config_1.firebase.firestore.Timestamp.fromDate(new Date(endAt)),
                    };
                    return [4 /*yield*/, newDoc.set(session)
                        // Don't wait to create the calendar event so it appears right away
                        // The backend hooks will ensure the details are correct afterwards
                    ];
                case 1:
                    _a.sent();
                    eventId = "centered-".concat(session.id);
                    return [4 /*yield*/, config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).set({
                            id: eventId,
                            type: 'centered',
                            ownerId: session.owner.id,
                            groupId: session.group.id,
                            owner: session.owner,
                            group: session.group,
                            scheduledSessionId: session.id,
                            name: session.title,
                            start: session.startAt.toMillis(),
                            end: session.endAt.toMillis(),
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/, newDoc.id];
            }
        });
    });
}
exports.createCenteredSessionFromCalendar = createCenteredSessionFromCalendar;
function renameCenteredSession(sessionId, user, title) {
    return __awaiter(this, void 0, void 0, function () {
        var eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, config_1.db
                        .doc("/scheduled-sessions/".concat(sessionId))
                        .withConverter((0, config_1.converter)())
                        .update({ title: title })];
                case 1:
                    _a.sent();
                    eventId = "centered-".concat(sessionId);
                    return [4 /*yield*/, config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).update({
                            name: title,
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.renameCenteredSession = renameCenteredSession;
function rescheduleCenteredSession(sessionId, user, startAt, endAt) {
    return __awaiter(this, void 0, void 0, function () {
        var eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, config_1.db
                        .doc("/scheduled-sessions/".concat(sessionId))
                        .withConverter((0, config_1.converter)())
                        .update({
                        startAt: config_1.firebase.firestore.Timestamp.fromDate(new Date(startAt)),
                        endAt: config_1.firebase.firestore.Timestamp.fromDate(new Date(endAt)),
                    })];
                case 1:
                    _a.sent();
                    eventId = "centered-".concat(sessionId);
                    return [4 /*yield*/, config_1.db.doc("users/".concat(user.id, "/visible-calendar-events/").concat(eventId)).update({
                            start: startAt.getTime(),
                            end: endAt.getTime(),
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.rescheduleCenteredSession = rescheduleCenteredSession;
function deleteCenteredSession(sessionId) {
    return __awaiter(this, void 0, void 0, function () {
        var user, sessionDoc, session, eventId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!config_1.auth.currentUser) {
                        throw new Error('User should be authenticated to delete a Centered Session');
                    }
                    return [4 /*yield*/, (0, getUser_1.getUser)(config_1.auth.currentUser.uid)];
                case 1:
                    user = _a.sent();
                    if (!user) {
                        throw new Error("Could not find user with id ".concat(config_1.auth.currentUser.uid));
                    }
                    return [4 /*yield*/, config_1.db
                            .doc("/scheduled-sessions/".concat(sessionId))
                            .withConverter((0, config_1.converter)())
                            .get()];
                case 2:
                    sessionDoc = _a.sent();
                    session = sessionDoc.data();
                    if (!session) {
                        throw new Error("Could not find session with id ".concat(sessionId));
                    }
                    if ((session === null || session === void 0 ? void 0 : session.owner.id) !== user.id) {
                        throw new Error("User ".concat(user.id, " is not the owner of session ").concat(sessionId));
                    }
                    return [4 /*yield*/, config_1.db.doc("/scheduled-sessions/".concat(sessionId)).delete()];
                case 3:
                    _a.sent();
                    eventId = "centered-".concat(session.id);
                    return [4 /*yield*/, config_1.db
                            .doc("users/".concat(session.owner.id, "/visible-calendar-events/").concat(eventId))
                            .delete()];
                case 4:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.deleteCenteredSession = deleteCenteredSession;
