"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCustomerPortalUrl = exports.createCheckoutSession = exports.SubscriptionPlans = void 0;
var helpers_1 = require("@centered/helpers");
var config_1 = require("../config");
var SubscriptionPlans;
(function (SubscriptionPlans) {
    SubscriptionPlans["Monthly"] = "new-monthly";
    SubscriptionPlans["Yearly"] = "new-yearly";
})(SubscriptionPlans = exports.SubscriptionPlans || (exports.SubscriptionPlans = {}));
function createCheckoutSession(plan, successUrl, promotionId) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var redirectUrl, docRef;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    redirectUrl = successUrl !== null && successUrl !== void 0 ? successUrl : helpers_1.WEB_URL;
                    return [4 /*yield*/, config_1.db
                            .collection('private-users')
                            .doc((_a = config_1.auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid)
                            .collection('checkout_sessions')
                            .withConverter((0, config_1.converter)())
                            .add(__assign({ price: plan, success_url: redirectUrl, cancel_url: redirectUrl, allow_promotion_codes: true, billing_address_collection: 'auto' }, (promotionId && { promotion_code: promotionId })))
                        // Wait for the CheckoutSession to get attached by the extension
                    ];
                case 1:
                    docRef = _b.sent();
                    // Wait for the CheckoutSession to get attached by the extension
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            var unsubscribe = docRef.onSnapshot(function (snap) { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                return __generator(this, function (_a) {
                                    data = snap.data();
                                    if (data === null || data === void 0 ? void 0 : data.sessionId) {
                                        unsubscribe();
                                        resolve({
                                            sessionId: data.sessionId,
                                        });
                                    }
                                    else if (data === null || data === void 0 ? void 0 : data.error) {
                                        unsubscribe();
                                        reject(data.error.message);
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (e) {
                                console.error('Error in createCheckoutSession snapshot listener', e);
                                unsubscribe();
                                reject(e);
                            });
                        })];
            }
        });
    });
}
exports.createCheckoutSession = createCheckoutSession;
function fetchCustomerPortalUrl(returnUrl) {
    return __awaiter(this, void 0, void 0, function () {
        var functionRef, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    functionRef = config_1.firebase
                        .app()
                        .functions()
                        .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
                    return [4 /*yield*/, functionRef({
                            returnUrl: returnUrl !== null && returnUrl !== void 0 ? returnUrl : "".concat(helpers_1.WEB_URL, "/dashboard"),
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.url];
            }
        });
    });
}
exports.fetchCustomerPortalUrl = fetchCustomerPortalUrl;
